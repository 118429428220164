import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/core"

import { Colors } from "../constants"
import { fadeIn, dropDown } from "../styles"

const Error = props => (
  <div
    css={css`
      position: relative;

      ${props.error &&
        css`
          &:after {
            content: "";
            left: 0;
            bottom: 0;
            height: 2px;
            width: 100%;
            display: block;
            position: absolute;
            background: ${Colors.error};
            animation: ${fadeIn} 0.2s;
          }
        `}
    `}
  >
    {props.children}
    {props.error && (
      <span
        css={css`
          left: 0;
          top: 100%;
          padding: 0 5px;
          display: block;
          font-size: 0.8em;
          position: absolute;
          color: ${Colors.error};
          animation: ${fadeIn} 0.2s, ${dropDown} 0.1s;
        `}
      >
        {props.error}
      </span>
    )}
  </div>
)

Error.propTypes = {
  error: PropTypes.string,
}

Error.defaultProps = {
  error: null,
}

export default Error
