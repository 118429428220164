import React from "react"
import { SvgIcon } from "@material-ui/core"

export const Stamp = props => (
  <SvgIcon {...props} viewBox="0 0 64 64">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63 31.5C63 48.897 48.897 63 31.5 63C14.103 63 0 48.897 0 31.5C0 14.103 14.103 0 31.5 0C48.897 0 63 14.103 63 31.5ZM37.5157 36.9684H41.1248C41.6885 36.9686 42.229 37.1926 42.6275 37.5912C43.0261 37.9897 43.2501 38.5302 43.2503 39.0939V47.3429H41.9084V48.5455C41.9084 48.7238 41.8375 48.8949 41.7114 49.021C41.5853 49.1471 41.4142 49.218 41.2359 49.218H21.7649C21.394 49.218 21.0924 48.9172 21.0924 48.5455V47.3429H19.7505V39.0931C19.7505 38.5296 19.9744 37.9892 20.3728 37.5907C20.7713 37.1923 21.3117 36.9684 21.8752 36.9684H25.4843C25.7777 36.9684 26.0682 36.9106 26.3393 36.7984C26.6103 36.6861 26.8566 36.5215 27.0641 36.3141C27.2715 36.1066 27.4361 35.8603 27.5484 35.5892C27.6607 35.3182 27.7184 35.0277 27.7184 34.7343V34.0216C27.7184 33.4058 27.2192 32.9065 26.6033 32.9065C25.9395 32.9065 25.3402 32.4269 25.2693 31.767C25.2387 31.4903 25.299 31.2112 25.4411 30.9718C25.5832 30.7324 25.7993 30.5457 26.0568 30.44C23.8715 28.5461 22.4579 25.5418 22.4579 22.1571C22.4579 16.4281 26.66 13.7805 31.6536 13.7805C36.6471 13.7805 40.5421 16.4281 40.5421 22.1571C40.5421 25.5418 39.1293 28.5469 36.9432 30.44C37.2007 30.5457 37.4169 30.7324 37.5589 30.9718C37.701 31.2112 37.7613 31.4903 37.7307 31.767C37.6598 32.4277 37.0605 32.9065 36.3967 32.9065C35.7809 32.9065 35.2816 33.4058 35.2816 34.0216V34.7343C35.2816 35.3268 35.517 35.8951 35.936 36.3141C36.3549 36.733 36.9232 36.9684 37.5157 36.9684Z"
    />
  </SvgIcon>
)

export const Google = props => (
  <SvgIcon {...props} viewBox="0 0 488 512">
    <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
  </SvgIcon>
)

export const Yelp = props => (
  <SvgIcon {...props} viewBox="0 0 384 512">
    <path d="M42.9 240.32l99.62 48.61c19.2 9.4 16.2 37.51-4.5 42.71L30.5 358.45a22.79 22.79 0 0 1-28.21-19.6 197.16 197.16 0 0 1 9-85.32 22.8 22.8 0 0 1 31.61-13.21zm44 239.25a199.45 199.45 0 0 0 79.42 32.11A22.78 22.78 0 0 0 192.94 490l3.9-110.82c.7-21.3-25.5-31.91-39.81-16.1l-74.21 82.4a22.82 22.82 0 0 0 4.09 34.09zm145.34-109.92l58.81 94a22.93 22.93 0 0 0 34 5.5 198.36 198.36 0 0 0 52.71-67.61A23 23 0 0 0 364.17 370l-105.42-34.26c-20.31-6.5-37.81 15.8-26.51 33.91zm148.33-132.23a197.44 197.44 0 0 0-50.41-69.31 22.85 22.85 0 0 0-34 4.4l-62 91.92c-11.9 17.7 4.7 40.61 25.2 34.71L366 268.63a23 23 0 0 0 14.61-31.21zM62.11 30.18a22.86 22.86 0 0 0-9.9 32l104.12 180.44c11.7 20.2 42.61 11.9 42.61-11.4V22.88a22.67 22.67 0 0 0-24.5-22.8 320.37 320.37 0 0 0-112.33 30.1z" />
  </SvgIcon>
)

export const CircleEmpty = props => (
  <SvgIcon {...props} viewBox="0 0 384 512">
    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z" />
  </SvgIcon>
)
