import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/core"

const Container = ({ className, children, element }) => {
  const Tag = element

  return (
    <Tag
      className={className}
      css={css`
        margin: 0 auto;
        max-width: 960px;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
      `}
    >
      {children}
    </Tag>
  )
}

Container.propTypes = {
  element: PropTypes.string,
}

Container.defaultProps = {
  element: `div`,
}

export default Container
