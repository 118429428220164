module.exports = {
  Email: `cajorge007@gmail.com`,
  Phone: `(909) 732-9837`,
  ComissionNumber: `F235243542`,
  GoogleMapsKey: `AIzaSyAe-r__p0LvPZO9CEQd7FFSu3_vhJiM3VE`,
  ServiceCoordinates: {
    lat: 34.0717081,
    lng: -117.7008266,
  },
  Colors: {
    grey1: `#F3F3F3`,
    grey2: `#E8E8E8`,
    white: `#ffffff`,
    black: `#333333`,
    primary: `#002868`,
    error: `#BF0A30`,
  },
  Reviews: [
    {
      rating: 5,
      message: `Jorge was on time and very professional. Our paperwork all checked out as expected from the receiving party. I would definitely recommend working with Jorge to anyone. `,
      author: `John Doe`,
    },
  ],
}
