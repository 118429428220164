/**
 * Check if string is empty with trim
 * @param {String} value
 */
export const isEmpty = value =>
  value === "" ||
  value === undefined ||
  value == null ||
  value.length === 0 ||
  /^\s+$/.test(value)

/**
 * Check if string is empty no trim
 * @param {String} value
 */
export const isEmptyNoTrim = value =>
  value === "" || value == null || value.length === 0

/**
 * Checks if {value} is between numbers {from} and {to}
 * @param {String} value
 * @param {String} from
 * @param {String} to
 * @returns {Boolean}
 */
export const isBetween = (value, from, to) =>
  (isEmpty(from) || value >= parseNumber(from)) &&
  (isEmpty(to) || value <= parseNumber(to))

/**
 * Parse price string
 * @param {String} value
 */
export const parseNumber = value => {
  var isDot, isComa

  if (typeof value !== "string") {
    return parseFloat(value)
  }
  isDot = value.indexOf(".")
  isComa = value.indexOf(",")

  if (isDot !== -1 && isComa !== -1) {
    if (isComa > isDot) {
      value = value.replace(".", "").replace(",", ".")
    } else {
      value = value.replace(",", "")
    }
  } else if (isComa !== -1) {
    value = value.replace(",", ".")
  }

  return parseFloat(value)
}

/**
 * Removes HTML tags and space characters, numbers and punctuation.
 *
 * @param {String} value - Value being stripped.
 * @return {String}
 */
export const stripHtml = value =>
  value
    .replace(/<.[^<>]*?>/g, " ")
    .replace(/&nbsp;|&#160;/gi, " ")
    .replace(/[0-9.(),;:!?%#$'"_+=\/-]*/g, "")

/**
 * Required
 *
 * @param {String} value
 * @return {Boolean}
 */
export const validateRequired = value => !isEmpty(value)

/**
 * Email
 *
 * @param {String} value
 * @return {Boolean}
 */
export const validateEmail = value =>
  isEmptyNoTrim(value) ||
  /^([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*@([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*\.(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]){2,})$/i.test(
    value
  )

/**
 * US phone number (strict)
 *
 * @param {String} value
 * @return {Boolean}
 */
export const validatePhoneStrict = value =>
  isEmptyNoTrim(value) || /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/.test(value)

/**
 * US phone number (strict)
 *
 * @param {String} value
 * @return {Boolean}
 */
export const validatePhoneRelaxed = value =>
  isEmptyNoTrim(value) ||
  /^((\d[\-. ]?)?((\(\d{3}\))|\d{3}))?[\-. ]?\d{3}[\-. ]?\d{4}$/.test(value)

/**
 * Alphabetical characters only
 *
 * @param {String} value
 * @return {Boolean}
 */
export const validateAlpha = value =>
  isEmptyNoTrim(value) || /^[a-zA-Z]+$/.test(value)

/**
 * Alpha-numerical characters only
 *
 * @param {String} value
 * @return {Boolean}
 */
export const validateAlphaNumeric = value =>
  isEmptyNoTrim(value) || /^[a-zA-Z]+$/.test(value)

/**
 * Alpha-numerical characters only
 *
 * @param {String} value
 * @return {Boolean}
 */
export const validateNumeric = value =>
  isEmptyNoTrim(value) ||
  (!isNaN(parseNumber(value)) && /^\s*-?\d*(\.\d*)?\s*$/.test(value))
