import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/core"

import ErrorComponent from "./error"
import { Colors } from "../constants"

const Input = props => (
  <ErrorComponent error={props.error}>
    <input
      {...props}
      css={css`
        margin: 0;
        border: 0;
        outline: 0;
        width: 100%;
        display: block;
        padding: 10px 15px;
        background: ${Colors.grey1};

        &:focus {
          background: ${Colors.grey2};
        }
      `}
    />
  </ErrorComponent>
)

Input.propTypes = {
  error: PropTypes.string,
}

Input.defaultProps = {
  error: null,
}

export default Input
