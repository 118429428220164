import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/core"

const Label = ({ element, children }) => {
  const Tag = element

  return (
    <Tag
      css={css`
        font-weight: bold;
        font-size: 0.8rem;
        text-transform: uppercase;
      `}
    >
      {children}
    </Tag>
  )
}

Label.propTypes = {
  element: PropTypes.string,
}

Label.defaultProps = {
  element: `label`,
}

export default Label
