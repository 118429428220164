import React, { useState } from "react"
import { css } from "@emotion/core"

import Row from "./row"
import Column from "./column"
import Label from "./label"
import { Input, Textarea } from "./form"
import Button from "./button"
import {
  validateEmail,
  validateRequired,
  validatePhoneRelaxed,
} from "./validation"

const ContactForm = () => {
  // Fields
  const [firstName, setFirstName] = useState(``)
  const [lastName, setLastName] = useState(``)
  const [email, setEmail] = useState(``)
  const [phone, setPhone] = useState(``)
  const [message, setMessage] = useState(``)
  const [submitted, setSubmitted] = useState(false)

  // Validation
  const validFirstName = () => validateRequired(firstName)
  const validLastName = () => validateRequired(lastName)
  const validPhone = () =>
    validateRequired(phone) && validatePhoneRelaxed(phone)
  const validEmail = () => validateRequired(email) && validateEmail(email)
  const valid = () =>
    validFirstName() && validLastName() && validPhone() && validEmail()

  // Submit
  const submitForm = event => {
    event.preventDefault()
    setSubmitted(true)

    if (valid()) {
      console.log("Submit form!!!")
    }
  }

  // Render
  return (
    <form onSubmit={submitForm}>
      <Row>
        <Column
          width="50%"
          css={css`
            padding-bottom: 30px;
          `}
        >
          <Label>First Name</Label>
          <Input
            name="first_name"
            value={firstName}
            error={
              submitted && !validFirstName()
                ? `Please provide your first name`
                : null
            }
            onChange={event => setFirstName(event.target.value)}
          />
        </Column>
        <Column
          width="50%"
          css={css`
            padding-bottom: 30px;
          `}
        >
          <Label>Last Name</Label>
          <Input
            name="last_name"
            value={lastName}
            error={
              submitted && !validLastName()
                ? `Please provide your last name`
                : null
            }
            onChange={event => setLastName(event.target.value)}
          />
        </Column>
        <Column
          width="50%"
          css={css`
            padding-bottom: 30px;
          `}
        >
          <Label>Email</Label>
          <Input
            name="email"
            type="email"
            value={email}
            error={
              submitted && !validEmail() ? `Please provide your email` : null
            }
            onChange={event => setEmail(event.target.value)}
          />
        </Column>
        <Column
          width="50%"
          css={css`
            padding-bottom: 30px;
          `}
        >
          <Label>Phone</Label>
          <Input
            name="phone"
            value={phone}
            error={
              submitted && !validPhone()
                ? `Please provide a phone number`
                : null
            }
            onChange={event => setPhone(event.target.value)}
          />
        </Column>
      </Row>
      <div
        css={css`
          padding-bottom: 30px;
        `}
      >
        <Label>Message</Label>
        <Textarea
          name="message"
          rows={5}
          value={message}
          onChange={event => setMessage(event.target.value)}
        />
      </div>
      <div
        css={css`
          text-align: right;
          padding-bottom: 30px;
        `}
      >
        <Button type="submit">Send Message</Button>
      </div>
    </form>
  )
}

export default ContactForm
