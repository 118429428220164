import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/core"

const Row = ({ element, children, className }) => {
  const Tag = element

  return (
    <Tag
      className={className}
      css={css`
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;
      `}
    >
      {children}
    </Tag>
  )
}

Row.propTypes = {
  element: PropTypes.string,
}

Row.defaultProps = {
  element: `div`,
}

export default Row
