/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import Header from "./header"
import { Colors } from "./constants"
import { Google, Yelp } from "./icons"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <Header
        siteTitle={
          <>
            <span
              css={css`
                font-weight: 100;
              `}
            >
              Just
            </span>
            <strong>Notaries</strong>
          </>
        }
        subTitle="Mobile Notary Public"
      />
      <main>{children}</main>
      <footer
        css={css`
          background: ${Colors.grey1};
        `}
      >
        <ul
          css={css`
            margin: 0;
            padding: 15px 0;
            display: block;
            list-style: none;
            text-align: center;
            font-size: 0.8rem;

            li {
              margin: 0;
              display: inline-block;
              vertical-align: top;
            }

            a {
              color: inherit;
              display: block;
              padding: 5px 15px;
              text-decoration: none;

              &:active,
              &:hover,
              &:focus {
                color: ${Colors.error};
                text-decoration: underline;
              }
            }
          `}
        >
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/services">Notary Services</Link>
          </li>
          <li>
            <Link to="/how-it-works">How It Works</Link>
          </li>
        </ul>

        <ul
          css={css`
            margin: 0;
            padding: 15px 0;
            display: block;
            list-style: none;
            text-align: center;
            font-size: 0.8rem;

            li {
              margin: 0;
              display: inline-block;
              vertical-align: top;
            }

            a {
              color: inherit;
              display: block;
              padding: 15px;
              text-decoration: none;

              &:active,
              &:hover,
              &:focus {
                color: ${Colors.error};
              }
            }
          `}
        >
          <li>
            <a
              href="https://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Google />
            </a>
          </li>
          <li>
            <a
              href="https://www.yelp.com/biz/jorge-castro-montclair"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Yelp />
            </a>
          </li>
        </ul>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
