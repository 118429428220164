import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/core"

const Column = ({ element, children, className, width }) => {
  const Tag = element

  return (
    <Tag
      className={className}
      css={css`
        padding-left: 15px;
        padding-right: 15px;
        flex: 0 0 ${width};
      `}
    >
      {children}
    </Tag>
  )
}

Column.propTypes = {
  element: PropTypes.string,
}

Column.defaultProps = {
  element: `div`,
}

export default Column
