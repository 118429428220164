import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import { ChevronRightSharp } from "@material-ui/icons"

import Container from "./container"
import { Colors } from "./constants"

const Breadcrumbs = ({ crumbs }) => (
  <div
    css={css`
      padding: 10px 0;
      font-size: 0.8rem;
      background: ${Colors.grey2};
    `}
  >
    <Container>
      {crumbs.map(({ label, url }, index) => (
        <>
          {index > 0 && (
            <ChevronRightSharp
              css={css`
                font-size: 1.25em !important;
                display: inline-block;
                vertical-align: middle;
                margin: 0 3px 1px 5px;
              `}
            />
          )}
          {url ? (
            <Link
              to={url}
              css={css`
                color: inherit;
              `}
            >
              {label}
            </Link>
          ) : (
            <span>{label}</span>
          )}
        </>
      ))}
    </Container>
  </div>
)

Breadcrumbs.propTypes = {
  crumbs: PropTypes.array.isRequired,
}

export default Breadcrumbs
