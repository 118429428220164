import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import { Colors } from "./constants"

const Button = ({
  children,
  className,
  element,
  type,
  href,
  target,
  onClick,
  outline,
  color,
  textColor,
  hoverColor,
  hoverTextColor,
}) => {
  const isLink = /^\//.test(href)
  const Tag = isLink ? Link : element

  return (
    <Tag
      className={className}
      onClick={onClick}
      to={isLink ? href : null}
      href={isLink ? null : href}
      type={element === "button" ? type : null}
      target={element === "a" ? target : null}
      rel="noopener noreferrer"
      css={css`
        cursor: pointer;
        text-align: center;
        border-radius: 3px;
        padding: 0.5em 1em;
        display: inline-block;
        text-decoration: none;
        color: ${outline ? color : textColor};
        background: ${outline ? `transparent` : color};
        border: 1px solid ${color};
        transition: all 0.2s;

        &:hover {
          ${outline &&
            css`
              background: ${color};
              color: ${hoverTextColor ? hoverTextColor : Colors.white};
            `}
          ${hoverColor &&
            css`
              background: ${hoverColor};
              border-color: ${hoverColor};
            `}
          ${hoverTextColor &&
            css`
              color: ${hoverTextColor};
            `}
        }
      `}
    >
      {children}
    </Tag>
  )
}

Button.propTypes = {
  element: PropTypes.string,
  type: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  outline: PropTypes.bool,
  color: PropTypes.string,
  textColor: PropTypes.string,
  hoverColor: PropTypes.string,
  hoverTextColor: PropTypes.string,
}

Button.defaultProps = {
  element: `button`,
  type: `button`,
  href: null,
  onClick: null,
  target: `_self`,
  outline: false,
  color: Colors.primary,
  textColor: Colors.white,
  hoverColor: null,
  hoverTextColor: null,
}

export default Button
