import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/core"
import { Event } from "@material-ui/icons"

import Container from "./container"
import Button from "./button"
import { Stamp } from "./icons"
import { Colors, ComissionNumber } from "./constants"
import { headingFont } from "./styles"

const Header = ({ siteTitle, subTitle }) => (
  <header
    css={css`
      color: white;
      background: #002868;
      padding: 1.45rem 1.0875rem;
    `}
  >
    <Container>
      <Stamp
        css={css`
          width: 60px !important;
          height: auto !important;
          vertical-align: top;
          padding-top: 10px;
        `}
      />
      <div
        css={css`
          display: inline-block;
          padding-left: 15px;
        `}
      >
        <Link
          to="/"
          css={css`
            margin: 0;
            color: inherit;
            display: block;
            line-height: 1;
            font-size: 2.25rem;
            padding-bottom: 10px;
            text-decoration: none;
            border-bottom: 1px solid #fff;
            font-family: ${headingFont};
          `}
        >
          {siteTitle}
        </Link>
        <span
          css={css`
            margin: 0;
            display: block;
            font-size: 1em;
            padding-top: 10px;
            font-weight: normal;
            text-align: center;
          `}
        >
          {subTitle}
        </span>
      </div>
      <div
        css={css`
          display: inline-block;
          float: right;
        `}
      >
        <Button
          element="a"
          target="_blank"
          href="https://calendly.com/loganstellway/30min?back=1&month=2020-02"
          color={Colors.grey1}
          hoverColor={Colors.white}
          textColor={Colors.primary}
        >
          <Event
            css={css`
              vertical-align: middle;
              margin-right: 10px;
            `}
          />
          Schedule a Signing
        </Button>
        <span
          css={css`
            display: block;
            padding: 8px 0 0;
            text-align: center;
            font-size: 0.8rem;
          `}
        >
          Comission #{ComissionNumber}
        </span>
      </div>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.any,
  subTitle: PropTypes.any,
}

Header.defaultProps = {
  siteTitle: ``,
  subTitle: ``,
}

export default Header
